import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'

import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'

import { Plugins } from '@capacitor/core'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import { connect } from './data/connect'
import { AppContextProvider } from './data/AppContext'
import { loadUserData } from './data/user/user.actions'

import Menu from './components/Menu'

import Account from './pages/Account'
import Login from './pages/Login'
import Signup from './pages/Signup'

import CustomMachine from './pages/CustomMachinePage'

import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'

const { SplashScreen, Storage } = Plugins

export let USE_LOCAL_ASSETS = false

let uri = 'https://dry-brushlands.herokuapp.com'
if (process.env.NODE_ENV === 'development') {
  uri = 'http://localhost:4444'
  USE_LOCAL_ASSETS = true
}

const client = new ApolloClient({
  uri: uri,
  credentials: 'include',
  request: async operation => {
    const token = (await Storage.get({ key: 'token' })).value
    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : ''
      }
    })
  }
})

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <AppContextProvider>
        <IonicAppConnected />
      </AppContextProvider>
    </ApolloProvider>
  )
}

interface StateProps {
  darkMode: boolean
}

interface DispatchProps {
  loadUserData: typeof loadUserData;
}

interface IonicAppProps extends StateProps, DispatchProps { }

const IonicApp: React.FC<IonicAppProps> = ({ darkMode, loadUserData }) => {
  useEffect(() => {
    loadUserData()
    // eslint-disable-next-line
  }, []);

  SplashScreen.hide()

  return (
    <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
      <IonReactRouter>
        <IonSplitPane contentId='main' when='(min-width: 2880px)'>
          <Menu />
          <IonRouterOutlet id='main'>
            <Route path='/' component={CustomMachine} exact />

            <Route path='/account' component={Account} />
            <Route path='/login' component={Login} />
            <Route path='/signup' component={Signup} />

            <Route path='/design' component={CustomMachine} exact />
            <Route path='/design/:id' component={CustomMachine} exact />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  )
}

export default App

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode
  }),
  mapDispatchToProps: { loadUserData },
  component: IonicApp
})
