import { Plugins } from '@capacitor/core'

const { Storage } = Plugins

export const KEY_HAS_LOGGED_IN = 'hasLoggedIn'
export const KEY_EMAIL = 'email'

export const KEY_NAME = 'name'

export async function getValue (key: string): Promise<string | null> {
  return (await Storage.get({ key })).value
}

export async function setValue (key: string, value: string) {
  if (!value) {
    await Storage.remove({ key })
  } else {
    await Storage.set({ key, value })
  }
}
