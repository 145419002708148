import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonInput, IonItem, IonLabel, IonButton, IonLoading, IonAlert, IonButtons, IonMenuButton, IonText } from '@ionic/react'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

import { connect } from '../data/connect'
import { setIsLoggedIn, setEmail, setToken } from '../data/user/user.actions'

import { Plugins } from '@capacitor/core'

const { Storage } = Plugins

const SIGN_IN_MUTATION = gql`
  mutation signin($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      id
      name
      token
    }
  }
`

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setEmail: typeof setEmail;
  setToken: typeof setToken;
}

interface LoginProps extends OwnProps, DispatchProps { }

const Login: React.FC<LoginProps> = ({ setIsLoggedIn, history, setEmail: setEmailAction, setToken }) => {
  const [signIn] = useMutation(SIGN_IN_MUTATION)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [showLoading, setShowLoading] = useState(false)

  const [alert, setAlert] = useState('')
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    async function resetValues () {
      await Storage.remove({ key: 'name' })

      await Storage.remove({ key: 'chefId' })
      await Storage.remove({ key: 'vessel1Id' })
      await Storage.remove({ key: 'vessel2Id' })
    }

    resetValues()

    setIsLoggedIn(false)
    setEmailAction(undefined)
    setToken(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onSignInButtonClick () {
    setShowLoading(true)
    try {
      const user = (await signIn({ variables: { email: email, password: password } })).data.signin
      await setToken(user.token)
      await setIsLoggedIn(true)
      await setEmailAction(email)

      await Storage.set({ key: 'name', value: user.name })

      history.push('/design', { direction: 'none' })
    } catch (error) {
      setAlert(error.message.replace('GraphQL error: ', ''))
      setShowAlert(true)
    }
    setShowLoading(false)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className='ion-padding'>
        <IonItem>
          <IonLabel position='floating'>
            Email <IonText color='danger'>*</IonText>
          </IonLabel>
          <IonInput
            type='email'
            value={email}
            onIonChange={e => setEmail((e.target as HTMLInputElement).value)}
          />
        </IonItem>
        <IonItem>
          <IonLabel position='floating'>
            Password <IonText color='danger'>*</IonText>
          </IonLabel>
          <IonInput
            type='password'
            value={password}
            onIonChange={e => setPassword((e.target as HTMLInputElement).value)}
          />
        </IonItem>

        <IonButton expand='block' type='submit' style={{ marginTop: '32px' }} onClick={() => onSignInButtonClick()}>Login</IonButton>

        <IonItem lines='none' style={{ marginTop: '16px' }}>
          <IonLabel>Don't have an account?</IonLabel>
          <IonButton fill='clear' slot='end' onClick={() => history.push('/signup')}>Sign Up</IonButton>
        </IonItem>

        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message='Loading...'
        />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          message={alert}
          buttons={['OK']}
        />
      </IonContent>
    </IonPage>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: { setIsLoggedIn, setEmail, setToken },
  component: Login
})
