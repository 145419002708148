import { getUserData, setIsLoggedInData, setEmailData, setTokenData, setHasSeenTutorialData } from '../dataApi'
import { ActionType } from '../../util/types'
import { UserState } from './user.state'

export const loadUserData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true))
  const data = await getUserData()
  dispatch(setData(data))
  dispatch(setLoading(false))
}

export const setLoading = (isLoading: boolean) => ({
  type: 'set-user-loading',
  isLoading
} as const)

export const setData = (data: Partial<UserState>) => ({
  type: 'set-user-data',
  data
} as const)

export const logoutUser = () => async (dispatch: React.Dispatch<any>) => {
  await setIsLoggedInData(false)
  dispatch(setEmail())
}

export const setIsLoggedIn = (loggedIn: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setIsLoggedInData(loggedIn)
  return ({
    type: 'set-is-loggedin',
    loggedIn
  } as const)
}

export const setEmail = (email?: string) => async (dispatch: React.Dispatch<any>) => {
  await setEmailData(email)
  return ({
    type: 'set-email',
    email
  } as const)
}

export const setToken = (token?: string) => async (dispatch: React.Dispatch<any>) => {
  await setTokenData(token)
  return ({
    type: 'set-token',
    token
  } as const)
}

export const setHasSeenTutorial = (hasSeenTutorial: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setHasSeenTutorialData(hasSeenTutorial)
  return ({
    type: 'set-has-seen-tutorial',
    hasSeenTutorial
  } as const)
}

export const setDarkMode = (darkMode: boolean) => ({
  type: 'set-dark-mode',
  darkMode
} as const)

export const setMenuEnabled = (menuEnabled: boolean) => ({
  type: 'set-menu-enabled',
  menuEnabled
} as const)

export type UserActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof setIsLoggedIn>
  | ActionType<typeof setEmail>
  | ActionType<typeof setToken>
  | ActionType<typeof setHasSeenTutorial>
  | ActionType<typeof setDarkMode>
  | ActionType<typeof setMenuEnabled>
