import React, { useState, useEffect } from 'react'

import { IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonToolbar, IonToggle, IonAvatar } from '@ionic/react'
import { logIn, personAdd, barcode, moon, calculator, home, paper, construct } from 'ionicons/icons'

import { connect } from '../data/connect'
import { RouteComponentProps, withRouter } from 'react-router'
import { setDarkMode } from '../data/user/user.actions'

import { Plugins } from '@capacitor/core'
import { KEY_NAME } from '../util/StorageUtils'
const { Storage } = Plugins

const routes = {
  loggedOutPages: [
    { title: 'Login', path: '/login', icon: logIn },
    { title: 'Signup', path: '/signup', icon: personAdd }
  ]
}

interface Pages {
  title: string,
  path: string,
  icon: { ios: string, md: string },
  routerDirection?: string
}
interface StateProps {
  darkMode: boolean;
  isAuthenticated: boolean;
  menuEnabled: boolean;
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode
}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps { }

const Menu: React.FC<MenuProps> = ({ darkMode, isAuthenticated, setDarkMode, menuEnabled }) => {
  const [userName, setUserName] = useState('')

  useEffect(() => {
    function capitalizeFirstLetter (string: string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    async function getUserName () {
      const tmpUserName = (await Storage.get({ key: KEY_NAME })).value
      setUserName(tmpUserName ? capitalizeFirstLetter(tmpUserName) : 'No Name')
    }
    getUserName()
  }, [isAuthenticated])

  function renderlistItems (list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide='false'>
          <IonItem button routerLink={p.path} routerDirection='none'>
            <IonIcon slot='start' icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ))
  }

  function renderAccountItem () {
    return (
      <IonMenuToggle auto-hide='false'>
        <IonItem button routerLink='/account' routerDirection='none'>
          <IonIcon slot='start' icon={barcode} />
          <IonLabel>{`${userName}`}</IonLabel>
        </IonItem>
      </IonMenuToggle>
    )
  }

  return (
    <IonMenu type='overlay' disabled={!menuEnabled} contentId='main'>
      <IonHeader>
        <IonToolbar>
          <IonItem lines='none'>
            <IonAvatar slot='start'>
              <img src='https://i.imgur.com/QolIuqS.png' alt='RoboChef Logo' />
            </IonAvatar>
            <IonLabel>RoboChef</IonLabel>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent class='outer-content'>
        <IonList lines='none'>
          <IonMenuToggle auto-hide='false'>
            <IonItem button href='https://www.robotickitchen.in' routerDirection='none'>
              <IonIcon slot='start' icon={home} />
              <IonLabel>Home</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>

        <IonList lines='none'>
          <IonListHeader>Account</IonListHeader>
          {isAuthenticated ? renderAccountItem() : renderlistItems(routes.loggedOutPages)}
        </IonList>

        <IonList lines='none'>
          <IonListHeader>Links</IonListHeader>

          <IonMenuToggle auto-hide='false'>
            <IonItem button href='https://www.robotickitchen.in/plan-your-automated-kitchen' routerDirection='none'>
              <IonIcon slot='start' icon={paper} />
              <IonLabel>Plan Your Automated Kitchen</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle auto-hide='false'>
            <IonItem button href='https://www.robotickitchen.in/design' routerDirection='none'>
              <IonIcon slot='start' icon={construct} />
              <IonLabel>Design Your RoboChef</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle auto-hide='false'>
            <IonItem button href='https://www.robotickitchen.in/roi-calculator' routerDirection='none'>
              <IonIcon slot='start' icon={calculator} />
              <IonLabel>ROI Calculator</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>

        <IonList lines='none'>
          <IonItem button onClick={() => setDarkMode(!darkMode)}>
            <IonIcon slot='start' icon={moon} />
            <IonLabel>Dark Theme</IonLabel>
            <IonToggle checked={darkMode} />
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedin,
    menuEnabled: state.user.menuEnabled
  }),
  mapDispatchToProps: ({
    setDarkMode
  }),
  component: withRouter(Menu)
})
