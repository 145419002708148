import React, { useState, useEffect } from 'react'
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonList, IonItem } from '@ionic/react'

import './Account.scss'

import { Plugins } from '@capacitor/core'
const { Storage } = Plugins

const Account: React.FC = () => {
  const [userName, setUserName] = useState('')

  useEffect(() => {
    async function getUserName () {
      const tmpUserName = (await Storage.get({ key: 'name' })).value
      setUserName(tmpUserName || 'No Name')
    }
    getUserName()
  }, [])

  return (
    <IonPage id='account-page'>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='ion-padding-top ion-text-center'>
          <img src='https://www.gravatar.com/avatar?d=mm&s=140' alt='avatar' />
          <h2>{userName}</h2>
          <IonList inset>
            <IonItem routerLink='/login' routerDirection='none'>Logout</IonItem>
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Account
