import React, { useState, useEffect, useRef } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonAvatar, IonList, IonItemDivider, IonRange, IonLabel, IonIcon, IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonItemGroup, IonListHeader, IonToggle, IonCardContent, IonModal, IonButton, IonButtons, IonToast, useIonViewDidEnter, IonText, IonLoading, IonGrid, IonRow, IonCol, IonMenuButton, IonSelect, IonSelectOption } from '@ionic/react'
import { square, water, snow, cafe, beaker, eye, eyeOff, cube, moon, sunny, flashOff, flash, informationCircle } from 'ionicons/icons'
import { gql } from 'apollo-boost'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'

import SplitPane from 'react-split-pane'

import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import '../theme/odometer-theme-minimal.css'
import './CustomMachinePage.scss'

import * as BABYLON from 'babylonjs'
import * as GUI from 'babylonjs-gui'

import 'babylonjs-loaders'

import BabylonScene from '../components/SceneComponent'
import { USE_LOCAL_ASSETS } from '../App'

import { getValue, KEY_HAS_LOGGED_IN } from '../util/StorageUtils'

const LOCAL_ASSETS_FOLDER = 'assets/glTF/'

let config = [
  { title: 'Vessels', shortTitle: 'Vessel', description: 'The main cooking vessels', value: 2, min: 2, max: 4, step: 1, unitCost: 50000, icon: beaker, visible: true, loadOnlyMainMesh: false, mainMeshName: 'Object_4', highlight: false, glow: false, cameraPosition: { x: -0.126, y: 0.776, z: -1.365 } },
  { title: 'Solid Containers', shortTitle: 'Solid', description: 'Containers which hold the solid bulk ingredients needed for cooking like rice, meat, vegetables, etc', value: 16, min: 10, max: 24, step: 2, unitCost: 20000, icon: square, visible: true, loadOnlyMainMesh: false, mainMeshName: 'Object_4', highlight: false, glow: false, cameraPosition: { x: 0.144, y: 3.153, z: 0.068 } },
  { title: 'Liquid Containers', shortTitle: 'Liquid', description: 'Containers which hold liquid ingredients like water, different types of oil, etc', value: 16, min: 10, max: 24, step: 2, unitCost: 5000, icon: water, visible: true, loadOnlyMainMesh: false, mainMeshName: 'TBD', highlight: false, glow: false, cameraPosition: { x: -0.126, y: 0.776, z: -1.365 } },
  { title: 'Powder Containers', shortTitle: 'Powder', description: 'Containers which hold powder ingredients like salt, sugar, masalas, spices, etc', value: 16, min: 10, max: 24, step: 1, unitCost: 10000, icon: snow, visible: true, loadOnlyMainMesh: false, mainMeshName: 'Object_10', highlight: false, glow: false, cameraPosition: { x: -1.665, y: 1.114, z: -0.3412 } },
  { title: 'Ingredient Collection Cups', shortTitle: 'Cup', description: 'Intermediate cups which collect the ingridients from various containers and puts them into the cooking vessels', value: 3, min: 3, max: 6, step: 1, unitCost: 30000, icon: cafe, visible: true, loadOnlyMainMesh: false, mainMeshName: 'Object_4', highlight: false, glow: false, cameraPosition: { x: -0.126, y: 0.776, z: -1.365 } }
  // { title: 'Vessel Capacity', shortTitle: 'Vessel', value: 37, min: 9, max: 72, step: 1, unitCost: 1000, icon: flask, visible: true, loadOnlyMainMesh: false, mainMeshName: 'TBD', highlight: false, glow: false, cameraPosition: { x: -0.126, y: 0.776, z: -1.365 } }
]

const originalConfig = [
  { shortTitle: 'Vessel', value: 2 },
  { shortTitle: 'Solid', value: 16 },
  { shortTitle: 'Liquid', value: 16 },
  { shortTitle: 'Powder', value: 16 },
  { shortTitle: 'Cup', value: 3 }
]

const assetContainerEntries = {
  Vessels: [],
  'Solid Containers': [],
  'Liquid Containers': [],
  'Powder Containers': [],
  'Ingredient Collection Cups': []
  // 'Vessel Capacity': []
}

let features = [
  { title: 'Automated Preparation System', cost: 50000, selected: false, optional: true },
  { title: 'Add Custom Recipes', cost: 50000, selected: false, optional: true },
  { title: 'Mobile App Control', cost: 50000, selected: false, optional: true },
  { title: 'Inventory Management', cost: 50000, selected: false, optional: true },
  { title: 'Cooking Planner', cost: 50000, selected: false, optional: true },
  { title: 'Recipes Auto-Scheduler', cost: 50000, selected: false, optional: true },
  { title: 'Preprocessing Smart Desk', cost: 50000, selected: false, optional: true },
  { title: 'CI/CD Live View', cost: 50000, selected: false, optional: true },
  { title: 'Hot Water Drain', cost: 50000, selected: false, optional: true },
  { title: 'Self Diagnosis', cost: 50000, selected: false, optional: true }
]

const models = {
  QX770: { basePrice: 9 },
  QX900: { basePrice: 12 },
  CX3700: { basePrice: 25 },
  CX5000: { basePrice: 30 },
  CX7200: { basePrice: 45 }
}

let uniqueKey = 0

const modalContent = ''

async function duplicateModels (cfg: any, scene: any, container: any, distanceMultiplier: number, xOffset: any, yOffset: any, zOffset: any) {
  if (componentToRerender !== '' && componentToRerender !== cfg.title) {
    return
  }

  for (let componentIndex = 0; componentIndex < (cfg.max / cfg.step); componentIndex++) {
    const meshName = `${cfg.title}_${componentIndex}`
    if (!cfg.visible) {
      const mesh = scene.getMeshByName(meshName)
      if (mesh) { mesh.setEnabled(false) }
      continue
    }

    let entries
    if (assetContainerEntries[cfg.title].length - 1 >= componentIndex) {
      entries = assetContainerEntries[cfg.title][componentIndex]
    } else {
      entries = container.instantiateModelsToScene(() => meshName)
      assetContainerEntries[cfg.title].push(entries)
    }

    if (componentIndex > (cfg.value / cfg.step) - 1) {
      scene.getMeshByName(meshName).setEnabled(false)
      continue
    } else {
      scene.getMeshByName(meshName).setEnabled(true)
    }

    for (const node of entries.rootNodes) {
      const baseMultiplier = Math.ceil(componentIndex / 2)
      let multiplier = 1
      if (componentIndex % 2 === 1) {
        multiplier = -1
      }
      node.position.x = xOffset + (baseMultiplier * distanceMultiplier * multiplier)
      node.position.y = yOffset
      node.position.z = zOffset

      if (cfg.title === 'Powder Containers' || cfg.title === 'Ingredient Collection Cups') {
        node.rotate(BABYLON.Axis.Y, Math.PI, BABYLON.Space.WORLD)
      }

      // eslint-disable-next-line no-loop-func
      node.getChildMeshes().forEach((mesh: any) => {
        if (mesh.id !== (`Clone of ${cfg.mainMeshName}.${cfg.mainMeshName}`)) {
          if (cfg.loadOnlyMainMesh) {
            mesh.setEnabled(false)
          } else {
            mesh.setEnabled(true)
          }

          if (cfg.glow) {
            mesh.material.emissiveColor = BABYLON.Color3.Teal()
          } else {
            mesh.material.emissiveColor = BABYLON.Color3.Black()
          }
        }

        if (cfg.highlight) {
          hl.addMesh(mesh, BABYLON.Color3.Green())
        } else {
          hl.removeMesh(mesh)
        }
      })
    }

    if (cfg.highlight || cfg.glow) {
      for (const group of entries.animationGroups) {
        group.play(true)
      }
    } else {
      for (const group of entries.animationGroups) {
        group.pause()
        group.reset()
      }
    }

    if (cfg.highlight) {
      createLabel(entries.rootNodes[0].getChildMeshes()[0], scene.textures[0], `${cfg.shortTitle} ${componentIndex + 1}`)
    }
  }

  if (cfg.highlight) {
    animateCamera(scene, cfg.cameraPosition)
  }
}

function animateCamera (scene: any, position: any) {
  var animationcamera = new BABYLON.Animation('myAnimationcamera', 'position', 30, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT)

  var keys = []

  keys.push({
    frame: 0,
    value: scene.activeCamera.position.clone()
  })

  keys.push({
    frame: 50,
    value: new BABYLON.Vector3(position.x, position.y, position.z)
  })

  animationcamera.setKeys(keys)

  scene.activeCamera.animations = []
  scene.activeCamera.animations.push(animationcamera)

  scene.beginAnimation(scene.activeCamera, 0, 100, false, 1)
}

function createLabel (mesh: any, advancedTexture: any, text: any) {
  var label = new GUI.Rectangle('label for ' + mesh.name)
  label.background = 'black'
  label.height = '30px'
  label.alpha = 0.5
  label.width = '100px'
  label.cornerRadius = 20
  label.thickness = 1
  label.linkOffsetY = 30
  advancedTexture.addControl(label)
  label.linkWithMesh(mesh)

  var text1 = new GUI.TextBlock()
  text1.text = text
  text1.color = 'white'
  label.addControl(text1)
}

const createCustomMachineMutation = gql`
  mutation createCustomMachineMutation($modelCode: String!, $config: Json!, $features: Json!, $totalCost: String!) {
    createCustomMachine(modelCode: $modelCode, config: $config, features: $features, totalCost: $totalCost) {
      id
    }
  }
`

const updateCustomMachineMutation = gql`
  mutation updateCustomMachineMutation($id: String!, $config: Json!, $features: Json!) {
    updateCustomMachine(id: $id, config: $config, features: $features) {
      id
    }
  }
`

const customMachineQuery = gql`
  query customMachineQuery($id: String!) {
    customMachine(id: $id) {
      requester {
        name
      }
      config
      features
    }
  }
`

let isFirstLazyData = true
let addModeDisplay = 'block'
let editModeDisplay = 'none'

let hl: any = null

const shouldRender = true
let shouldRerender = true
let componentToRerender = ''

let vesselFrameAssetContainer: any = null
let vHopperBasePlateAssetContainer: any = null
let rHopperBasePlateAssetContainer: any = null
let cupBoxRailCupAssetContainer: any = null

const enclosureMeshes: any = []

let mainSceneEngine: BABYLON.Engine | null = null

const CustomMachinePage: React.FC<RouteComponentProps> = ({ match, history }) => {
  const [getCustomMachine, { data: customMachineData }] = useLazyQuery(customMachineQuery, { fetchPolicy: 'no-cache' })
  const [createCustomMachine] = useMutation(createCustomMachineMutation)
  const [updateCustomMachine] = useMutation(updateCustomMachineMutation)

  const [modelCode, setModelCode] = useState('QX770')
  const [totalCost, setTotalCost] = useState(getTotalCost())

  const [showModal, setShowModal] = useState(false)

  const [showSavingToast, setShowSavingToast] = useState(false)

  const [show3dModel, setShow3dModel] = useState(false)
  const [showMain3dModel, setShowMain3dModel] = useState(false)
  const [modalContentDimensions, setModalContentDimensions] = useState({ width: 600, height: 544 })

  const [reactRender, setReactRender] = useState(false)

  const [showLoading, setShowLoading] = useState(true)

  const odometerRef = useRef<any>(null)
  const modalContentRef = useRef<any>(null)

  const modelPaneRef = useRef<any>(null)

  function switchToEditMode () {
    addModeDisplay = 'none'
    editModeDisplay = 'block'
  }

  function getTotalCost () {
    let totalCost = (models[modelCode].basePrice * 100000)
    for (let index = 0; index < config.length; index++) {
      const cfg = config[index]
      const originalCfg = originalConfig[index]

      const countDifferenceFromDefault = Math.abs(originalCfg.value - cfg.value)
      totalCost += (cfg.unitCost * countDifferenceFromDefault)
    }
    for (let index = 0; index < features.length; index++) {
      const feature = features[index]
      if (feature.selected) {
        totalCost += feature.cost
      }
    }

    return totalCost
  }

  useEffect(() => {
    setTotalCost(getTotalCost())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelCode])

  function onMainSceneMount (e: any) {
    const { scene, canvas, engine } = e
    mainSceneEngine = engine

    const xRayMaterial = new BABYLON.StandardMaterial('xray', scene)
    xRayMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1)
    xRayMaterial.alpha = 0.1
    const fresnelParams1 = new BABYLON.FresnelParameters()
    fresnelParams1.isEnabled = true
    fresnelParams1.leftColor = new BABYLON.Color3(0.5, 0.6, 1)
    fresnelParams1.rightColor = new BABYLON.Color3(0, 0, 0)
    fresnelParams1.power = 2
    fresnelParams1.bias = 0.1
    const fresnelParams2 = new BABYLON.FresnelParameters()
    fresnelParams2.isEnabled = true
    fresnelParams2.leftColor = new BABYLON.Color3(1, 1, 1)
    fresnelParams2.rightColor = new BABYLON.Color3(0.2, 0.2, 0.2)
    fresnelParams2.power = 2
    fresnelParams2.bias = 0.5
    xRayMaterial.emissiveFresnelParameters = fresnelParams1
    xRayMaterial.opacityFresnelParameters = fresnelParams2

    const advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI')

    const logo = new GUI.Image('logo', 'assets/img/logo_with_text.png')
    logo.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT
    logo.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
    logo.width = 0.25
    logo.height = 0.25
    logo.stretch = GUI.Image.STRETCH_UNIFORM
    logo.onPointerClickObservable.add(() => { window.open('https://robochef.co') })
    advancedTexture.addControl(logo)

    var panel = new GUI.StackPanel()
    panel.width = '20%'
    panel.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT
    panel.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER
    advancedTexture.addControl(panel)

    const showEnclosureCheckbox = new GUI.Checkbox()
    showEnclosureCheckbox.width = '20px'
    showEnclosureCheckbox.height = '20px'
    showEnclosureCheckbox.color = 'green'
    showEnclosureCheckbox.isChecked = true

    const showEnclosureCheckboxHeader = GUI.Control.AddHeader(showEnclosureCheckbox, 'Show Enclosure', '180px', { isHorizontal: true, controlFirst: true })
    showEnclosureCheckboxHeader.color = 'white'
    showEnclosureCheckboxHeader.height = '20px'
    showEnclosureCheckboxHeader.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
    panel.addControl(showEnclosureCheckboxHeader)

    showEnclosureCheckbox.onIsCheckedChangedObservable.add(function (checked) {
      if (checked) {
        enclosureMeshes.forEach((mesh: any) => {
          mesh.setEnabled(true)
        })
      } else {
        enclosureMeshes.forEach((mesh: any) => {
          mesh.setEnabled(false)
        })
      }
    })

    var xRayViewEnclosureCheckbox = new GUI.Checkbox()
    xRayViewEnclosureCheckbox.width = '20px'
    xRayViewEnclosureCheckbox.height = '20px'
    xRayViewEnclosureCheckbox.color = 'green'
    xRayViewEnclosureCheckbox.isChecked = true

    var xRayViewEnclosureCheckboxHeader = GUI.Control.AddHeader(xRayViewEnclosureCheckbox, 'Enclosure X-Ray', '180px', { isHorizontal: true, controlFirst: true })
    xRayViewEnclosureCheckboxHeader.color = 'white'
    xRayViewEnclosureCheckboxHeader.height = '20px'
    xRayViewEnclosureCheckboxHeader.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
    panel.addControl(xRayViewEnclosureCheckboxHeader)

    xRayViewEnclosureCheckbox.onIsCheckedChangedObservable.add(function (checked) {
      if (checked) {
        xRayMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1)
        xRayMaterial.alpha = 0.1
        fresnelParams1.isEnabled = true
        fresnelParams2.isEnabled = true
      } else {
        xRayMaterial.emissiveColor = BABYLON.Color3.Black()
        xRayMaterial.alpha = 1
        fresnelParams1.isEnabled = false
        fresnelParams2.isEnabled = false
      }
    })

    const logoMat = new BABYLON.StandardMaterial('robochef-logo', scene)
    logoMat.diffuseTexture = new BABYLON.Texture('assets/img/web_hi_res_512.png', scene)
    logoMat.diffuseTexture.hasAlpha = true

    const ground = BABYLON.Mesh.CreateGround('ground', 5, 5, 1, scene, false)
    ground.material = logoMat

    const gl = new BABYLON.GlowLayer('glow', scene)

    hl = new BABYLON.HighlightLayer('hl1', scene)
    let alpha = 0
    scene.registerBeforeRender(() => {
      alpha += 0.1
      gl.intensity = Math.cos(alpha) * 10
    })

    Promise.all([
      BABYLON.SceneLoader.LoadAssetContainerAsync(USE_LOCAL_ASSETS ? LOCAL_ASSETS_FOLDER : 'https://dl.dropbox.com/s/keyfkuj2z5d863y/',
        'vessel-assembly-frame.glb', scene).then(function (container) {
        vesselFrameAssetContainer = container
      }),
      BABYLON.SceneLoader.LoadAssetContainerAsync(USE_LOCAL_ASSETS ? LOCAL_ASSETS_FOLDER : 'https://dl.dropbox.com/s/8t2yzgj21cw8jsv/',
        'vhopperplate-with-hopper-new.glb', scene).then(function (container) {
        vHopperBasePlateAssetContainer = container
      }),
      BABYLON.SceneLoader.LoadAssetContainerAsync(USE_LOCAL_ASSETS ? LOCAL_ASSETS_FOLDER : 'https://dl.dropbox.com/s/8neb5vnhr2nv0cq/',
        'rhopper_container_in_frame_open.glb', scene).then(function (container) {
        rHopperBasePlateAssetContainer = container
      }),
      BABYLON.SceneLoader.LoadAssetContainerAsync(USE_LOCAL_ASSETS ? LOCAL_ASSETS_FOLDER : 'https://dl.dropbox.com/s/80yb160ijxtmfq5/',
        'linear_rail.glb', scene).then(function (container) {
        const entries = container.instantiateModelsToScene()
        for (const node of entries.rootNodes) {
          node.position.x = 0.196
          node.position.y = 0.801
          node.position.z = 0.044
        }
      }),
      BABYLON.SceneLoader.LoadAssetContainerAsync(USE_LOCAL_ASSETS ? LOCAL_ASSETS_FOLDER : 'https://dl.dropbox.com/s/pigul5jmkmpj50p/',
        'cupbox_single.glb', scene).then(function (container) {
        cupBoxRailCupAssetContainer = container
      }),
      BABYLON.SceneLoader.LoadAssetContainerAsync(USE_LOCAL_ASSETS ? LOCAL_ASSETS_FOLDER : 'https://dl.dropbox.com/s/i67wm4eounw04r9/',
        'masalarails_sensor_edited.glb', scene).then(function (container) {
        const entries = container.instantiateModelsToScene()

        for (const node of entries.rootNodes) {
          node.rotate(BABYLON.Axis.Y, Math.PI / 2, BABYLON.Space.WORLD)

          node.position.x = 0.224
          node.position.y = 0.637
          node.position.z = -0.308
        }
      }),
      BABYLON.SceneLoader.LoadAssetContainerAsync(USE_LOCAL_ASSETS ? LOCAL_ASSETS_FOLDER : 'https://dl.dropbox.com/s/llh9s5oi8weoxri/',
        'robochef-main-frame.glb', scene).then(function (container) {
        const entries = container.instantiateModelsToScene()

        for (const node of entries.rootNodes) {
          node.position.x = 0.334
          node.position.y = 0.261
          node.position.z = 0.721
        }
      }),
      BABYLON.SceneLoader.LoadAssetContainerAsync(USE_LOCAL_ASSETS ? LOCAL_ASSETS_FOLDER : 'https://dl.dropbox.com/s/aik53ri7ubsyu5t/',
        'vessel1_side_enclosure.glb', scene).then(function (container) {
        const entries = container.instantiateModelsToScene()
        for (const node of entries.rootNodes) {
          enclosureMeshes.push(...node.getChildMeshes())

          node.rotate(BABYLON.Axis.Y, Math.PI / 2 * -1, BABYLON.Space.WORLD)

          node.position.x = -0.815
          node.position.y = 0.778
          node.position.z = 0.195
        }
      }),
      BABYLON.SceneLoader.LoadAssetContainerAsync(USE_LOCAL_ASSETS ? LOCAL_ASSETS_FOLDER : 'https://dl.dropbox.com/s/p6qjwto709uy69y/',
        'vessel2_side_enclosre.glb', scene).then(function (container) {
        const entries = container.instantiateModelsToScene()
        for (const node of entries.rootNodes) {
          enclosureMeshes.push(...node.getChildMeshes())

          node.rotate(BABYLON.Axis.Y, Math.PI / 2 * -1, BABYLON.Space.WORLD)

          node.position.x = 1.436
          node.position.y = 0.570
          node.position.z = 0.188
        }
      }),
      BABYLON.SceneLoader.LoadAssetContainerAsync(USE_LOCAL_ASSETS ? LOCAL_ASSETS_FOLDER : 'https://dl.dropbox.com/s/pjec832r3cg7hq0/',
        'back-enclosure.glb', scene).then(function (container) {
        const entries = container.instantiateModelsToScene()
        for (const node of entries.rootNodes) {
          enclosureMeshes.push(...node.getChildMeshes())

          node.position.x = 0.019
          node.position.y = 0.783
          node.position.z = 0.618
        }
      }),
      BABYLON.SceneLoader.LoadAssetContainerAsync(USE_LOCAL_ASSETS ? LOCAL_ASSETS_FOLDER : 'https://dl.dropbox.com/s/i9evi8r2uwcbctw/',
        'front_vessel_enclosure.glb', scene).then(function (container) {
        const entries = container.instantiateModelsToScene()
        for (const node of entries.rootNodes) {
          enclosureMeshes.push(...node.getChildMeshes())

          node.rotate(BABYLON.Axis.Y, Math.PI, BABYLON.Space.WORLD)

          node.position.x = 0.153
          node.position.y = 0.152
          node.position.z = -0.417
        }
      }),
      BABYLON.SceneLoader.LoadAssetContainerAsync(USE_LOCAL_ASSETS ? LOCAL_ASSETS_FOLDER : 'https://dl.dropbox.com/s/b2hk9imp1tesww7/',
        'rhopper-enclosure.glb', scene).then(function (container) {
        const entries = container.instantiateModelsToScene()
        for (const node of entries.rootNodes) {
          enclosureMeshes.push(...node.getChildMeshes())

          node.rotate(BABYLON.Axis.Y, Math.PI / 2 * -1, BABYLON.Space.WORLD)

          node.position.x = 0.178
          node.position.y = -0.185
          node.position.z = 0.238
        }
      })
    ]).then(() => {
      scene.createDefaultCameraOrLight(true, true, true)

      scene.activeCamera.useAutoRotationBehavior = true
      scene.activeCamera.autoRotationBehavior.idleRotationSpeed = 0.1

      scene.activeCamera.lowerRadiusLimit = 1
      scene.activeCamera.upperRadiusLimit = 3

      enclosureMeshes.forEach((mesh: any) => {
        gl.addExcludedMesh(mesh)

        mesh.material = xRayMaterial

        const colors = []
        const count = mesh.getTotalVertices() * 4
        for (let i = 0; i < count; i += 4) {
          colors[i] = 1
          colors[i + 1] = 1
          colors[i + 2] = 1
          colors[i + 3] = 1
        }
        mesh.setVerticesData(BABYLON.VertexBuffer.ColorKind, colors)
      })

      setShowLoading(false)
    })

    engine.runRenderLoop(async function () {
      if (canvas.width === 0 && canvas.height === 0) {
        if (modelPaneRef.current) {
          canvas.width = modelPaneRef.current.offsetWidth
          canvas.height = modelPaneRef.current.offsetHeight
        }
        engine.resize()
      }

      if (shouldRender && scene && scene.activeCamera) {
        if (vesselFrameAssetContainer && vHopperBasePlateAssetContainer &&
          rHopperBasePlateAssetContainer && cupBoxRailCupAssetContainer && shouldRerender) {
          shouldRerender = false
          for (let index = 0; index < config.length; index++) {
            const cfg = config[index]
            if (cfg.title === 'Vessels') {
              duplicateModels(cfg, scene, vesselFrameAssetContainer, 0.48, 0.48, 0, 0)
            } else if (cfg.title === 'Solid Containers') {
              duplicateModels(cfg, scene, vHopperBasePlateAssetContainer, 0.2, 0.409, 1.152, 0.179)
            } else if (cfg.title === 'Powder Containers') {
              duplicateModels(cfg, scene, rHopperBasePlateAssetContainer, 0.125, 0.342, 1.194, -0.214)
            } else if (cfg.title === 'Ingredient Collection Cups') {
              duplicateModels(cfg, scene, cupBoxRailCupAssetContainer, 0.125, 0.177, 0.867, 0.142)
            }
          }
          componentToRerender = ''
        }

        scene.render()
      }
    })
  }

  function onSceneMount (e: any) {
    const { scene, engine } = e

    BABYLON.SceneLoader.ImportMesh('', 'https://dl.dropbox.com/s/isbgraw7e9batyl/', '1_cube_moving.glb', scene, function () {
      scene.createDefaultCameraOrLight(true, true, true)
    })

    engine.runRenderLoop(() => {
      if (scene && scene.activeCamera) {
        scene.render()
      }
    })
  }

  useIonViewDidEnter(() => {
    if ('id' in match.params) {
      switchToEditMode()
      // eslint-disable-next-line
      getCustomMachine({ variables: { id: match.params['id'] } })
    }
  })

  useEffect(() => {
    getValue(KEY_HAS_LOGGED_IN).then(value => {
      if (value === 'true') {
        setShowMain3dModel(true)
      } else {
        history.push('/login', { direction: 'none' })
        setShowLoading(false)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (modalContentRef.current) {
      setModalContentDimensions({
        width: modalContentRef.current.offsetWidth,
        height: modalContentRef.current.offsetHeight
      })
    }
  }, [modalContentDimensions.height, modalContentDimensions.width, show3dModel])

  useEffect(() => {
    odometerRef.current!.innerHTML = totalCost.toLocaleString('en-IN')
  }, [totalCost])

  if (customMachineData && isFirstLazyData) {
    isFirstLazyData = false
    config = customMachineData.customMachine.config
    features = customMachineData.customMachine.features

    setTotalCost(getTotalCost())
  }

  const rangeListHtml = []
  for (let index = 0; index < config.length; index++) {
    const cfg = config[index]

    let color = 'primary'
    if (cfg.value === cfg.min) {
      color = 'danger'
    } else if (cfg.value === cfg.max) {
      color = 'success'
    }

    rangeListHtml.push(
      <IonItem key={uniqueKey++}>
        <IonLabel>
          <IonText color={color}>{cfg.value} {(cfg.title === 'Vessel Capacity') && 'Liter'}</IonText> {cfg.title}
        </IonLabel>
        <Tippy content={cfg.description}>
          <IonIcon icon={informationCircle} slot='end' />
        </Tippy>
      </IonItem>
    )
    rangeListHtml.push(
      <IonItem key={uniqueKey++}>
        <IonRange
          pin snaps min={cfg.min} max={cfg.max} step={cfg.step} value={cfg.value}
          // eslint-disable-next-line no-loop-func
          debounce={1000} onIonChange={e => {
            cfg.value = (e.detail.value as number)
            setTotalCost(getTotalCost())

            componentToRerender = cfg.title
            shouldRerender = true
          }}
        >
          <IonIcon size='small' slot='start' icon={cfg.icon} />
          <IonLabel slot='start'>{cfg.min}</IonLabel>
          <IonLabel slot='end'>{cfg.max}</IonLabel>
          <IonIcon slot='end' size='large' icon={cfg.icon} />
        </IonRange>
      </IonItem>
    )
    rangeListHtml.push(
      <IonItem key={uniqueKey++}>
        <IonGrid>
          <IonRow>
            <IonCol size='3'>
              <Tippy content={cfg.visible ? 'Hide' : 'Show'}>
                <IonButton
                  expand='full' fill='clear'
                  // eslint-disable-next-line no-loop-func
                  onClick={() => {
                    cfg.visible = !cfg.visible
                    setReactRender(!reactRender)

                    componentToRerender = cfg.title
                    shouldRerender = true
                  }}
                >
                  <IonIcon slot='icon-only' icon={cfg.visible ? eyeOff : eye} />
                </IonButton>
              </Tippy>
            </IonCol>

            <IonCol size='3'>
              <Tippy content={cfg.loadOnlyMainMesh ? 'Load Full Model' : 'Load Minimal Model'}>
                <IonButton
                  expand='full' fill='clear'
                  // eslint-disable-next-line no-loop-func
                  onClick={() => {
                    cfg.loadOnlyMainMesh = !cfg.loadOnlyMainMesh
                    setReactRender(!reactRender)

                    componentToRerender = cfg.title
                    shouldRerender = true
                  }}
                >
                  <IonIcon slot='icon-only' icon={cfg.loadOnlyMainMesh ? cube : square} />
                </IonButton>
              </Tippy>
            </IonCol>

            <IonCol size='3'>
              <Tippy content={cfg.highlight ? 'Remove Highlight' : 'Highlight Component'}>
                <IonButton
                  expand='full' fill='clear'
                  // eslint-disable-next-line no-loop-func
                  onClick={() => {
                    cfg.highlight = !cfg.highlight
                    setReactRender(!reactRender)

                    componentToRerender = cfg.title
                    shouldRerender = true
                  }}
                >
                  <IonIcon slot='icon-only' icon={cfg.highlight ? moon : sunny} />
                </IonButton>
              </Tippy>
            </IonCol>
            <IonCol size='3'>
              <Tippy content={cfg.glow ? 'Remove Glow' : 'Make Component Glow'}>
                <IonButton
                  expand='full' fill='clear'
                  // eslint-disable-next-line no-loop-func
                  onClick={() => {
                    cfg.glow = !cfg.glow
                    setReactRender(!reactRender)

                    componentToRerender = cfg.title
                    shouldRerender = true
                  }}
                >
                  <IonIcon slot='icon-only' icon={cfg.glow ? flashOff : flash} />
                </IonButton>
              </Tippy>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    )
    rangeListHtml.push(<IonItemDivider key={uniqueKey++} />)
  }

  const featuresListHtml = []
  for (let index = 0; index < features.length; index++) {
    const feature = features[index]
    featuresListHtml.push(
      <IonItem key={index}>
        <IonCard style={{ width: '100%' }}>
          <IonCardContent>
            <IonItem lines='none'>
              <IonToggle
                slot='start' disabled={!feature.optional} checked={feature.selected}
                onIonChange={e => {
                  feature.selected = e.detail.checked
                  setTotalCost(getTotalCost())
                }}
              />
              <IonLabel>{feature.title}</IonLabel>
              {/* <IonButton
                slot='end' fill='clear'
                // eslint-disable-next-line no-loop-func
                onClick={() => {
                  modalContent = feature.title
                  setShowModal(true)
                }}
              >
                <IonIcon slot='icon-only' icon={informationCircle} />
              </IonButton> */}
            </IonItem>
          </IonCardContent>
        </IonCard>
      </IonItem>
    )
  }

  const modelsSelectOptions = []
  for (let i = 0; i < Object.keys(models).length; i++) {
    const modelCode = Object.keys(models)[i]
    modelsSelectOptions.push(<IonSelectOption key={modelCode} value={modelCode}>{modelCode}</IonSelectOption>)
  }
  const costCard = (
    <IonCard style={{ width: '100%' }}>
      <IonCardHeader>
        <IonList>
          <IonItem>
            <IonLabel>RoboChef Model</IonLabel>
            <IonSelect
              value={modelCode}
              onIonChange={async e => setModelCode((e.target as HTMLIonSelectElement).value)}
            >
              {modelsSelectOptions}
            </IonSelect>
          </IonItem>
        </IonList>

        <IonCardTitle>₹ <div id='odometer' className='odometer' ref={odometerRef}>0</div></IonCardTitle>
        <IonCardSubtitle>Plus applicable taxes</IonCardSubtitle>
      </IonCardHeader>

      <IonButton
        disabled={showSavingToast}
        expand='block' style={{ display: addModeDisplay }}
        onClick={async () => {
          setShowSavingToast(true)
          const customMachine: any = await createCustomMachine({ variables: { modelCode, config, features, totalCost } })
          setShowSavingToast(false)
          history.push(`/design/${customMachine.data.createCustomMachine.id}`)
        }}
      >
          Save Custom Machine
      </IonButton>

      <IonButton
        disabled={showSavingToast}
        expand='block' style={{ display: editModeDisplay, marginBottom: '16px' }}
        onClick={async () => {
          setShowSavingToast(true)
          // eslint-disable-next-line
          await updateCustomMachine({ variables: { id: match.params['id'], config, features } })
          setShowSavingToast(false)
        }}
      >
          Update Custom Machine
      </IonButton>
    </IonCard>
  )

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonMenuButton />
          </IonButtons>
          <IonItem lines='none'>
            <IonAvatar slot='end'>
              <a href='https://www.robotickitchen.in/'>
                <img src='https://i.imgur.com/QolIuqS.png' alt='RoboChef Logo' />
              </a>
            </IonAvatar>
            <IonTitle>Design Your RoboChef</IonTitle>
          </IonItem>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <SplitPane split='vertical' defaultSize='70%' onResizerClick={() => mainSceneEngine!.resize()}>
          <div ref={modelPaneRef} style={{ width: '100%', height: '100%' }}>
            {showMain3dModel &&
              <BabylonScene
                style={{ width: '100%', height: '100%' }}
                onSceneMount={onMainSceneMount}
              />}
          </div>

          <IonContent>
            <IonItemGroup>
              <IonItemDivider sticky>
                {costCard}
              </IonItemDivider>

              <IonList lines='none'>
                {rangeListHtml}
              </IonList>

              <IonList lines='none'>
                <IonListHeader>
                  <IonLabel>Features</IonLabel>
                </IonListHeader>

                {featuresListHtml}
              </IonList>
            </IonItemGroup>
          </IonContent>

          <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <IonHeader translucent>
              <IonToolbar>
                <IonTitle>{modalContent}</IonTitle>
                <IonButtons slot='end'>
                  <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent fullscreen ref={modalContentRef}>
              <IonItem>
                <IonLabel>{modalContent}</IonLabel>
                <IonButton slot='end' onClick={() => setShow3dModel(!show3dModel)}>Load Model</IonButton>
              </IonItem>
              {show3dModel &&
                <BabylonScene
                  style={{
                    width: `${modalContentDimensions.width}px`,
                    height: `${modalContentDimensions.height}px`
                  }}
                  width={modalContentDimensions.width}
                  height={modalContentDimensions.height}
                  onSceneMount={onSceneMount}
                />}
            </IonContent>
          </IonModal>
        </SplitPane>

        <IonToast isOpen={showSavingToast} message='Saving Custom Machine.' />
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message='Loading 3D Model...'
        />
      </IonContent>
    </IonPage>
  )
}

export default CustomMachinePage
