import { Plugins } from '@capacitor/core'

const { Storage } = Plugins

const HAS_LOGGED_IN = 'hasLoggedIn'
const HAS_SEEN_TUTORIAL = 'hasSeenTutorial'
const EMAIL = 'email'
const TOKEN = 'token'

export const getUserData = async () => {
  const response = await Promise.all([
    Storage.get({ key: HAS_LOGGED_IN }),
    Storage.get({ key: HAS_SEEN_TUTORIAL }),
    Storage.get({ key: EMAIL }),
    Storage.get({ key: TOKEN })])
  const isLoggedin = await response[0].value === 'true'
  const hasSeenTutorial = await response[1].value === 'true'
  const email = await response[2].value || undefined
  const token = await response[3].value || undefined
  const data = {
    isLoggedin,
    hasSeenTutorial,
    email,
    token
  }
  return data
}

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await Storage.set({ key: HAS_LOGGED_IN, value: JSON.stringify(isLoggedIn) })
}

export const setHasSeenTutorialData = async (hasSeenTutorial: boolean) => {
  await Storage.set({ key: HAS_SEEN_TUTORIAL, value: JSON.stringify(hasSeenTutorial) })
}

export const setEmailData = async (email?: string) => {
  if (!email) {
    await Storage.remove({ key: EMAIL })
  } else {
    await Storage.set({ key: EMAIL, value: email })
  }
}

export const setTokenData = async (token?: string) => {
  if (!token) {
    await Storage.remove({ key: TOKEN })
  } else {
    await Storage.set({ key: TOKEN, value: token })
  }
}
